@layer components {
  .table {
    @apply table-fixed w-full text-sm  opacity-90;
    border-collapse: collapse;
    // border-spacing: 5px 2px;
    border-spacing: 0px;
    color:white;
  }

  .table {
    tr {
      // @apply bg-white-dirty bg-opacity-80 rounded shadow transition-colors duration-150;
      @apply  bg-opacity-80  transition-colors duration-150;

      &[data-clickable='true'] {
        // @apply hover:bg-red cursor-pointer;
      }
    }
  }
.table {
  tr[data-clickable='true']:hover {
    background-color: rgba(99, 99, 102, 0.2);
    filter: brightness(120%);
    cursor:pointer;
  }
} 

  .table thead tr {
    // @apply bg-white-dirty shadow-none relative;
    @apply  shadow-none relative;
  
  }
  .table thead th::after {
    @apply absolute block w-full bg-gray bg-opacity-70 bottom-0 left-0;
    content: '';
    // height: 2px;
  }

  .table td,
  .table th {
    // @apply px-18 py-18 overflow-hidden overflow-ellipsis text-center;
    @apply  overflow-hidden overflow-ellipsis text-center;
    border-right:5px solid rgb(2,2,15,0.1);
    border-left:5px solid rgb(2,2,15,0.1);
    padding-left: 5px;

  }

  .table td{
    border-top:5px solid rgb(2,2,15,0.1);
  }

  .table th {
    // @apply px-18 py-18 overflow-hidden overflow-ellipsis text-center;
    @apply  overflow-hidden overflow-ellipsis text-center;
    border-right:5px solid rgb(2,2,15,1);
    border-left:5px solid rgb(2,2,15,1);
    padding-left: 5px;

  }

  .table td:first-child,
  .table th:first-child {
  //  border:none;
  border-left: none;
  }

  .table td:last-child,
  .table th:last-child {
  //  border:none;
  border-right: none;
  }

  .table td {
    @apply relative;
  }

  .table th {
    // @apply bg-white-dirty relative z-30;
    @apply relative z-30;
    // background: rgba(2,2,15,0.5);
    background: rgba(16,25,40,1);
    // background: rgba(1,);
    padding: 9px 12px;
text-transform: uppercase;

    font-size: 11px;
    line-height: 15px;
    height: 48px; 

    & > div {
      & > span {
        opacity: 1;

        & > div {
          width: 100%;
        }
      }
    }
  }

  .table tr:nth-child(even) td {
    height: 48px;
    background: rgba(163,158,157,0.05);
   color:white;
   font-size: 15px;
   font-weight: 300;
   letter-spacing: 0.375;
   
      & > span {
        opacity: 0.7 !important;
        width: 100%;

        & > div {
          width: 100%;
        }
      }
    
  }
  .table tr:nth-child(odd) td {
    height: 48px;
    background: rgba(163,158,157,0.09);
   color:white;
   font-size: 15px;
   font-weight: 300;
   letter-spacing: 0.375;


      & > span {
        opacity: 0.7 !important;
        width: 100%;

        & > div {
          width: 100%;
        }
      }
    
  }

  .table td:not(:first-child)::before,
  .table th:not(:first-child)::before {
    // @apply bg-gray-light bg-opacity-40;
    // @apply bg-gray-light bg-opacity-40;
    // content: '';
    // height: 80%;
    // left: 0;
    // position: absolute;
    // top: 10%;
    // width: 1px;
  }

  // .table td:first-child {
  //   @apply rounded-l;
  // }

  // .table td:last-child {
  //   @apply rounded-r;
  // }
}
