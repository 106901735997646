.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(3px);

  .react-confirm-alert-body {
    background-color: rgba(2, 2, 15, 1);
    color: #fff;
    font-weight: lighter;
    line-height: 27px;
    width: 600px;
    h1 {
      font-size: larger;
      margin-bottom: 20px;
      font-weight: bolder;
    }
    .react-confirm-alert-button-group {
      margin-top: 30px;
      button:nth-child(1) {
        background: #00e5ff;
        color: black;
        font-size: 13px;
        font-family: "inter";
        font-weight: bold;
      }

      button:nth-child(2) {
        background: rgba(156, 176, 196, 0.11);
        color: white;
        font-size: 13px;
        font-family: "inter";
        font-weight: bold;
        border: 1px solid rgb(68, 85, 97);
      }
    }
  }
}
