@tailwind base;
@tailwind components;
@import './table.scss';
@import './buttons.scss';
@import './formControl.scss';

@tailwind utilities;

:root {
  --topbar-height: 0px;
}

.ReactModal__Overlay {
  background: rgba(0, 0, 0, 0.6) !important;
}
/* fonts import */
@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Inter', BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  text-rendering: optimizeSpeed;
}
html {
  background: #020e19;
}

body {
  background: radial-gradient(
    at 57% 57%,
    rgba(159, 153, 150, 0.3) 0%,
    rgba(0, 0, 0, 0.3) 100%
  );
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

button,
button:hover,
button:focus,
a,
a:hover,
a:focus,
input,
input:focus {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

input {
  @apply w-full;
  text-align: inherit;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='datetime-local']::-webkit-calendar-picker-indicator {
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  cursor: pointer;
}

input[type='date']::-webkit-calendar-picker-indicator {
  padding-left: 10px;
  padding-right: 10px;
  color: red;
  cursor: pointer;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@layer components {
  .detailInfo {
    @apply flex justify-between items-center px-18 py-12 bg-white bg-opacity-80 gap-x-4 md:w-80 shadow-sm border border-gray-light border-opacity-5;
    min-height: 45px;
  }

  .detailInfo .detailInfo__label {
    @apply w-max;
  }

  .tileInfo {
    @apply flex-1 text-sm  opacity-80;
  }

  .tileInfo__label {
    @apply font-semibold inline-block pr-2;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 400;
    color: rgb(163, 158, 157);

    &:first-letter {
      @apply uppercase;
    }
  }

  // .tileInfo__label:after {
  //   content: ': ';
  // }

  .navItem {
    @apply grid items-center text-sm cursor-pointer py-8 px-18;
    grid-template-columns: 2rem 1fr;
  }
}
