.dot-label {
  color: white;
  z-index: 20 !important;
  width: 92px !important;
  height: 24px !important;
  background: #0091ff;
  bottom: -10px !important;

  > span {
    width: 92px !important;
    height: 24px !important;
    border-radius: 0 !important;
    bottom: -12px !important;
    left: 0 !important;
    padding-bottom: 0 !important;
    z-index: 1 !important;
    display: none !important ;
  }
}

.future {
  padding-bottom: 0 !important;
  z-index: 20 !important;
}

.present {
  padding-bottom: 0 !important;
  z-index: 20 !important;
}

.events-wrapper {
  margin: -17px 40px 0 40px !important;
}

.button-forward {
  top: 34px !important;
  border: none !important;
  background-image: url('../../../../../assets/icons/next-arrow.png') !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;

  > svg {
    display: none !important;
    height: 100% !important;
    width: 100% !important;
    background: #0091ff;
    > g {
      > path {
        fill: white;
      }
    }
  }
}

.button-back {
  top: 34px !important;
  border: none !important;
  background-image: url('../../../../../assets/icons/prev-arrow.png') !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;

  > svg {
    display: none !important;
    height: 100% !important;
    width: 100% !important;
    background: #0091ff;
    > g {
      > path {
        fill: white;
      }
    }
  }
}

.events {
  height: 5px !important;
}
