$inputPadding: 10px;

@layer components {
  .formControl {
    @apply flex w-full items-center  text-right text-xs  relative;
    min-width: 280px;
text-align: right;
display: flex;
justify-content: flex-end;


input::-webkit-input-placeholder {
  color: white; /*Change the placeholder color*/
  opacity: 1; /*Change the opacity between 0 and 1*/
}

    input,
    textarea {
      @apply py-12 px-18 ;
      background: transparent;
 color: white;
 font-size: 15px;
 font-weight: 500;
    }

    label {
      @apply duration-150 transition-transform font-regular capitalize-first;
    }

    &__outline {
      @apply absolute inset-0 flex 
      pointer-events-none;
      grid-template-columns: min-content max-content 1fr;
      justify-content: flex-end;

      & > * {
        // @apply flex items-center border border-gray border-opacity-30 h-full duration-150 transition-colors;
        @apply flex items-center  h-full duration-150 transition-colors;
        padding-top:25px;
    
      }

      &--item1 {
        @apply pl-18 border-r-0 ;
      }
      &--item2 {
        @apply border-r-0 border-l-0;
        padding: 0
      }
      &--item3 {
        @apply flex-1 border-l-0 ;
      }
    }

    &--textarea {
      @apply pr-0 pt-12;
      .formControl__outline {
        @apply text-xs;
        & > * {
          @apply items-start py-12;
        }
      }
    }

    &--disabled {
      @apply opacity-80 pointer-events-none;
    }

    &--hasError {
      .formControl__outline {
        & > * {
          @apply border-red;
        }
      }
      label {
        @apply text-red;
      }
    }

    // &--hasValue,
    // &:focus-within {
    //   @apply text-left justify-start;

    //   label {
    //     @apply transform -translate-y-5 -translate-x-1 scale-90;
    //   }

    //   .formControl__outline {
    //     &--item1 {
    //       @apply pl-12;
    //     }
    //     &--item2 {
    //       @apply  pr-4 pl-8;
    //       padding-top: 25px;
    //     }
    //   }
    // }

    &:focus-within,
    &:active {
      .formControl__outline {
        & > * {
          @apply border-blue-light;
        }
      }
      label {
        @apply text-blue-light;
      }
    }

    &:hover {
      .formControl__outline {
        & > * {
          @apply border-opacity-50;
        }
      }
    }
  }
}
