@layer components {
  .button {
    @apply flex items-center justify-center
    transition-all duration-200
     text-xs
     hover:opacity-95
    shadow-md  border-gray;
    height: 33px;
    border-radius: 0;
    // padding-left: 39px;
    // padding-right: 39px;
    // padding-top: 8px;
    // padding-bottom: 8px;

    // padding-bottom: 30px;

     padding-left: 44px;
     padding-right: 44px;


  }

  .button--normal {
    @apply bg-white border;
  }

  .button--abort {
    @apply text-white  border-white;
    background-color: rgba(163, 158, 157, 0.3);
  }

  .button--submit {
    @apply bg-blue text-white  border-white;
    background: rgba(0, 145, 255, 0.7);
  }

  .button--add {
    background: #00E5FF;
    color:black;
    font-size: 13px;
    font-family:'inter';
    font-weight: bold;
  
  }

  .button--save {
    background: #02020F;
    color:#00DDF7;
    font-size: 13px;
    font-family:'inter';
    font-weight: bold;
    border: 1px solid #00DDF7;
  }

  .button--delete {
    background: #02020F;
    color:#FF3700;
    font-size: 13px;
    font-family:'inter';
    font-weight: bold;
    border: 1px solid #FF3700;
  }

  .button--cancel {
    background: rgba(156,176,196,0.11);
    color:white;
    font-size: 13px;
    font-family:'inter';
    font-weight: bold;
    border: 1px solid rgb(68,85,97);
  }

  .button--edit {
    background: #9CB0C4;
    color:black;
    font-size: 13px;
    font-family:'inter';
    font-weight: bold;
  }

  .button--disabled {
    @apply opacity-60  pointer-events-none shadow-none;
  }
}
